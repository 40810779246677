export default {
  data() {
    return {
      mobile: '',
      code: '',
      areaCodeList: [],
      areaCode: '',
      time: 60,
      isGetCode: false,
      //是否获取验证码
      timer: '' //定时器
    };
  },

  mounted() {
    this.getAreaCode();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取区号
    getAreaCode() {
      this.$http.getAreaCode().then(res => {
        if (res.code == 1) {
          this.areaCodeList = res.data;
          this.areaCode = res.data[0];
        } else {
          this.$message.error(res.data.msg);
        }
        console.log(res);
      }).catch(v => {
        console.log(v);
      });
    },
    // 区号点击
    areaCodeClick(command) {
      this.areaCode = command;
    },
    // 获取验证码
    getCode() {
      if (!this.mobile) {
        this.$message.info(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
        return;
      }
      let data = {
        type: 7,
        is_test: 0,
        mobile_code: this.areaCode,
        mobile: this.mobile
      };
      this.$http.getCode(data).then(res => {
        if (res.code == 1) {
          this.$message.success(this.$t('sendSuccess'));
          this.isGetCode = true;
          this.timer = setInterval(() => {
            if (this.time == 1) {
              clearInterval(this.timer);
              this.time = 60;
              this.isGetCode = false;
            }
            this.time--;
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(v => {});
    },
    confirmClick() {
      if (!this.mobile) {
        this.$message.info(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
        return;
      }

      // if (!this.code) {
      // 	this.$message.info(this.$t('tips.codeCannotBeEmpty'))
      // 	return;
      // }
      this.code = '123456';
      this.$http.bindingPhone({
        mobile_code: this.areaCode,
        mobile: this.mobile,
        code: this.code,
        // step: 2,
        type: 7
      }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          let newUserinfo = {
            ...this.$store.state.userinfo,
            mobile: this.mobile
          };
          this.$store.commit('edituserinfo', newUserinfo);
          this.$router.back();
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};