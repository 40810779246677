var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_vm._m(0), _c('div', {
    staticClass: "login_title"
  }, [_vm._v(_vm._s(_vm.$t('set.bindingPhone')))])])]), _c('div', {
    staticClass: "cur_account"
  }), _c('div', {
    staticClass: "input_item"
  }, [_vm._m(1), _c('div', {
    staticClass: "dropdown"
  }, [_c('el-dropdown', {
    on: {
      "command": _vm.areaCodeClick
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_vm._v(" " + _vm._s(_vm.areaCode)), _c('i', {
    staticClass: "el-icon-caret-bottom el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.areaCodeList, function (item, index) {
    return _c('el-dropdown-item', {
      key: index,
      attrs: {
        "command": item
      }
    }, [_vm._v(_vm._s(item) + " ")]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mobile,
      expression: "mobile"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.inputPhone')
    },
    domProps: {
      "value": _vm.mobile
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.mobile = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.confirmClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('confirm')) + " ")])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/login/phone.png")
    }
  })]);
}];
export { render, staticRenderFns };